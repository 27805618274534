<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="inline-flex align-center">
            <h3 class="pl-2 text-uppercase">Practice Test .</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-4 flex justify-between flex-wrap justify-xs-center">
          <SelectComponent placeholder="READ ALOUD" class="w-64 m-1" />
          <SearchBox placeholder="Search" class="w-64 outline-gray-400 p-2 m-1" />
        </div>
        <md-divider></md-divider>
        <Table :items="practiceTests" @onSelect="onSelect" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="STD_ NAME">
              <span class="ml-2 rounded p-1 bg-victoria text-white">{{ item.studentName }}</span>
            </md-table-cell>
            <md-table-cell md-label="Q_ID" class="text-victoria">{{ item.qId }}</md-table-cell>
            <md-table-cell md-label="QUESTION INDEX" class="text-victoria">{{ item.questionIndex }}</md-table-cell>
            <md-table-cell md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-label="PART OF">Practice</md-table-cell>
            <md-table-cell md-label="ASSIGN BY" class="text-victoria">{{ item.assignBy }}</md-table-cell>
            <md-table-cell md-label="EXAM DATE">{{ item.examDate }}</md-table-cell>
            <md-table-cell md-label="FEEDBACK" class="text-tahiti-gold">{{ item.feedback }}</md-table-cell>
            <md-table-cell md-label="ADDED WITH FINAL SCORE" class="text-tahiti-gold">{{ item.addedWithFinalScore }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">chevron_right</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, Table } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import data from "@/data/pending-evaluation/data";
export default {
  data() {
    return {
      practiceTests: data.practiceTests
    };
  },
  components: {
    Card,
    Table,
    CardBody,
    SearchBox,
    SelectComponent
  },
  methods: {
    onSelect() {

    }
  }
};
</script>

<style></style>
