<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="inline-flex align-center">
            <md-icon>healing</md-icon>
            <h3 class="pl-2 text-uppercase">Practice Test</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-4 flex justify-between justify-xs-center">
          <SelectComponent class="w-64 m-1" />
        </div>
        <md-divider></md-divider>
        <Table :items="feedbackTableData" @onSelect="onSelect" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="Q_ID" class="text-victoria">{{ item.qId }}</md-table-cell>
            <md-table-cell md-label="QUESTION INDEX" class="text-victoria">{{ item.questionIndex }}</md-table-cell>
            <md-table-cell md-label="TEST ID" class="text-victoria">{{ item.testId }}</md-table-cell>
            <md-table-cell md-label="STD_ NAME" class="text-victoria">{{ item.studentName }}</md-table-cell>
            <md-table-cell md-label="PART OF">Practice</md-table-cell>
            <md-table-cell md-label="PRE SCORE">{{ item.preScore }}</md-table-cell>
            <md-table-cell md-label="NEW SCORE">{{ item.newScore }}</md-table-cell>
            <md-table-cell md-label="EXAM DATE">{{ item.examDate }}</md-table-cell>
            <md-table-cell md-label="EVALUATION DATE">{{ item.evaluationDate }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">chevron_right</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, Table } from "@/components";
import data from "@/data/pending-evaluation/data.js";
export default {
  data() {
    return {
      feedbackTableData: data.feedbackTableData,
    };
  },
  components: {
    Card,
    Table,
    CardBody,
    SelectComponent,
  },
  methods: {
    onSelect() {

    }
  }
};
</script>

<style></style>
