<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="inline-flex align-center">
            <h3 class="pl-2 text-uppercase">Practice Test</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <!-- <SelectComponent placeholder="READ ALOUD" class="w-64 m-1" /> -->
          <SearchBox placeholder="Search" class="w-64 outline-gray-400 p-2 m-1" />
        </div>
        <md-divider></md-divider>
        <Table :items="practiceTests" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
            <md-table-cell md-label="STD_ NAME">
              <span class="ml-2 rounded p-1 bg-victoria text-white">{{ item.student_name }}</span>
            </md-table-cell>
            <md-table-cell md-label="EMAIL">{{ item.student_email }}</md-table-cell>
            <md-table-cell md-label="TEST NAME">{{ item.test }}</md-table-cell>
            <md-table-cell md-label="PART OF">{{ item.part_of }}</md-table-cell>
            <!-- <md-table-cell md-label="ASSIGN BY" class="text-victoria">{{ item.assignBy }}</md-table-cell> -->
            <md-table-cell md-label="EXAM DATE">{{ item.created_at }}</md-table-cell>
            <md-table-cell md-label="STATUS" class="text-tahiti-gold">{{ (item.active == 1) ? 'Active': 'Inactive' }}</md-table-cell>
            <!-- <md-table-cell md-label="ADDED WITH FINAL SCORE" class="text-tahiti-gold">{{ item.addedWithFinalScore }}</md-table-cell> -->
            <md-table-cell>
                <span class="action">
                    <md-icon 
                      @click.native="goTOEvaluationPage(item)"
                      class="bg-victoria rounded-full p-1 text-white text-base mr-1">chevron_right</md-icon>
                </span>
            </md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, Table } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      practiceTests: []
    };
  },
  components: {
    Card,
    Table,
    CardBody,
    SearchBox,
    // SelectComponent
  },
  methods: {
    ...mapActions({
      actExamEnrollList: "examination/actExamEnrollList"
    }),
    goTOEvaluationPage(examEnroll) {
      this.$router.push({
          name: 'pendingEvaluation.manual.practiceTest.evaluation',
          query: {
            enrollId: examEnroll.id
          }
      })
    }
  },
  created() {
    this.actExamEnrollList().then(({ items }) => {
      this.practiceTests = items.map((item, index) => {
        item.sl = index + 1;
        return item;
      });
    });
  }
};
</script>