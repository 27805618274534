<template>
  <div>
    <h2 class="text-uppercase text-center">
        <span style="border-bottom: 1px solid #cbd5e0">Manual Evaluation</span>
    </h2>
    
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content  label-class="text-gray-900" label="Student ID" text="STD 1254" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content  label-class="text-gray-900" label="Student Name" text="Faysal Khan" />
      </div>
    </div>
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content  label-class="text-gray-900" label="Test ID" text="TES 2561" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content  label-class="text-gray-900" label="Test Name" text="Roobchada" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content  label-class="text-gray-900" label="Question ID" :text="enrollment.q_id" />
      </div>
    </div>
    <div class="md-layout md-gutter mt-6">
        <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-large-size-40 md-xlarge-size-25">
            <div>
                <h2 class="text-base text-uppercase">Question type</h2>
                <p class="mt-4 text-lg mb-8 font-semibold text-victoria">{{ enrollment.question_type }}</p>
            </div>
            <div class="mt-4 pr-6">
                <h2 class="text-victoria text-uppercase text-italic text-lg">Enabling skills</h2>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Grammar</label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.grammar"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">%</span>
                    </div>
                </div>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Pronunciation</label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.pronunciation"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">%</span>
                    </div>
                </div>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Fluency</label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.fluency"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">%</span>
                    </div>
                </div>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Spelling </label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.spelling"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">%</span>
                    </div>
                </div>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Discourse</label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.discourse"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">%</span>
                    </div>
                </div>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Vocabulary</label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.vocabulary"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">%</span>
                    </div>
                </div>

            </div>
            <div class="mt-4 pr-6">
                <h2 class="text-victoria text-uppercase text-italic text-lg">Communication Skill</h2>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Reading</label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.reading"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">/ {{ enrollment.reading }}</span>
                    </div>
                </div>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Writing</label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.writing"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">/ {{ enrollment.writing }}</span>
                    </div>
                </div>
                
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Speaking</label>

                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.speaking"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">/ {{ enrollment.speaking }}</span>
                    </div>
                </div>
                <div class="flex justify-between align-center my-4">
                    <label class="text-uppercase text-lg font-semibold text-italic">Listening</label>
                    <div class="flex align-center">
                        <InputFieldComponent
                            v-model="evaluation.listening"
                            class="w-24"
                        />
                        <span class="ml-1 text-lg">/ {{ enrollment.listening }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-large-size-60 md-xlarge-size-75">
            <div class="flex flex-col justify-between outline-gray-400 p-6 h-full rounded">
            <div>
                <div>
                    <h2 class="mb-4 text-uppercase text-italic font-semibold">Question</h2>
                    <div class="line-height-27 text-base text-justify" v-html="enrollment.title"></div>
                </div>
                <div>
                    <h2 class="my-4 text-uppercase text-italic font-semibold">Student answer</h2>
                    <p class="line-height-27 text-base text-justify text-danger opacity-75">
                        {{ studentAnswer }}
                    </p>
                </div>
                <!-- <div>
                    <h2 class="my-4 text-uppercase text-italic font-semibold">Corect answer</h2>
                    <p class="line-height-27 text-base text-justify opacity-75">Asperiores, nesciunt. Quos,</p>
                </div> -->

            </div>
            <div class="flex justify-between ">
                <h2 class="opacity-50">
                    {{ meta.current_page }} of {{ meta.total }}
                </h2>
                <div class="flex">
                    <div
                        class="p-2 mx-1 pointer outline-gray-400 rounded"
                        @click="changeQuestion(meta.current_page - 1)"
                        >
                        <md-icon>chevron_left</md-icon>
                    </div>
                    <div 
                        class="p-2 mx-1 pointer outline-gray-400 rounded"
                        @click="changeQuestion(meta.current_page + 1)"
                        >
                        <md-icon>chevron_right</md-icon>
                    </div>

                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="md-layout md-gutter my-3">
        <div class="md-layout-item">
             <label
                  for=""
                  class="mb-2 pr-4 block text-lg text-uppercase font-semibold text-italic"
                  >Feedback</label
                >
                <textarea
                  v-model="evaluation.comment"
                  class="w-full rounded outline-gray-400 p-2"
                  cols="30"
                  rows="10"
                ></textarea>
        </div>
        
        <div class="md-layout-item w-full flex flex-col align-end justify-end">
            <md-button class="bg-victoria text-white rounded mr-0" @click="submitEvaluation">Submit</md-button>
        </div>
    </div>
  </div>
</template>

<script>
import { InputFieldComponent } from "@/components";
import Content from "@/components/atom/Content";
import { mapActions, mapMutations } from 'vuex';
export default {
  components: {
    InputFieldComponent,
    Content,
  },
  data() {
      return {
          enrollment: {},
          meta: {},
          evaluation: {
              grammar: '',
              pronunciation: '',
              vocabulary: '',
              discourse: '',
              spelling: '',
              fluency: '',
              listening: '',
              speaking: '',
              writing: '',
              reading: '',
              comment: ''
          }
      }
  },
  computed: {
    examEnrollId() {
        return this.$route.query.enrollId
    },
    studentAnswer() {
        if(this.enrollment.answer_sheet && this.enrollment.answer_sheet.length > 0) {
            return this.enrollment.answer_sheet[0].answer_sheet_details[0].answer
        }
        return '';
    }
  },
  methods: {
    ...mapMutations({
        setShowSnackbar: "setShowSnackbar",
    }),
    ...mapActions({
        actExamEnrollPreview: "examination/actExamEnrollPreview",
        actSaveAnswerEvaluation: "examination/actSaveAnswerEvaluation",
    }),
    submitEvaluation() {
        let id = this.enrollment.answer_sheet[0].id;
        let checkList = ['reading', 'writing', 'listening', 'speaking'];
        Object.keys(this.evaluation).map(item => {
            if(checkList.includes(item) && this.evaluation[item] == '') {
                this.evaluation[item] = '0'
            }
        })
        this.actSaveAnswerEvaluation({
            answerSheetId: id,
            data: this.evaluation
        }).then(({ message }) => this.setShowSnackbar(message));
    },
    changeQuestion(pageNumber) {
        if(pageNumber < 1 || pageNumber > this.meta.total) {
            this.setShowSnackbar('No more question');
            return;
        }

        this.actExamEnrollPreview(`${this.examEnrollId}?page=${pageNumber}`)
        .then(({items, meta}) => {
            this.enrollment = items[0]
            this.meta = meta
        })
    }
  },
  created() {
      this.actExamEnrollPreview(`${this.examEnrollId}?page=1`)
      .then(({items, meta}) => {
          this.enrollment = items[0];
          this.meta = meta;
          if(this.enrollment.answer_sheet.length > 0) {
            this.evaluation = this.enrollment.answer_sheet[0]
          }
         
      })
  }
};
</script>